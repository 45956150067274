export default {
  LOAD_DATA: "LOAD_DATA",
  SET_STATE: "SET_STATE",
  CLEAR_PHOTO: "CLEAR_PHOTO",
  UPLOAD_PHOTO: "UPLOAD_PHOTO",
  ADD_PHOTO: "ADD_PHOTO",
  REMOVE_PHOTO: "REMOVE_PHOTO",
  LOAD_INGREDIENTS: "LOAD_INGREDIENTS",
  LOAD_RESTAURANTS: "LOAD_RESTAURANTS",
  SET_TRANSLATIONS: "SET_TRANSLATIONS",
};
