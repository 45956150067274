import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import { UserPlusIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  IconButton,
} from "@material-tailwind/react";
import { useReducer } from "react";
import { dataTableReducer } from "./reducer";
import actions from "./actions";
import SlideOver from "../SlideOver";

const Datatable = ({
  title,
  description,
  tabs,
  heads,
  data = [],
  RowCells,
  FormComponent,
  handleAdd,
  handleUpdate,
  handleDelete,
  handleSearch,
  addButtonLabel,
}) => {
  const datatableInitialState = {
    objectToEdit: null,
    isFormOpen: false,
  };

  const [state, dispatch] = useReducer(dataTableReducer, datatableInitialState);

  const setOpen = (value) => {
    dispatch({
      type: actions.OPEN_FORM,
      value,
    });
  };

  const handleAddHandler = (...args) => {
    handleAdd(...args);
    dispatch({type:actions.OPEN_FORM, value:false})
  }

  const handleUpdateHandler = (...args) => {
    handleUpdate(...args);
    dispatch({type:actions.OPEN_FORM, value:false})
  }

  
  return (
    <>
      <Card className="h-full w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-8 flex items-center justify-between gap-8">
            <div>
              <Typography variant="h5" color="blue-gray">
                {title}
              </Typography>
              <Typography color="gray" className="mt-1 font-normal">
                {description}
              </Typography>
            </div>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              <Button variant="outlined" color="blue-gray" size="sm">
                view all
              </Button>
              {handleAdd && (
                <Button
                  className="flex items-center gap-3 bg-main"
                  size="sm"
                  onClick={() => dispatch({ type: actions.OPEN_FORM })}
                >
                  <UserPlusIcon strokeWidth={2} className="h-4 w-4" />
                  {addButtonLabel}
                </Button>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            {tabs && (
              <Tabs value="all" className="w-full md:w-max">
                <TabsHeader>
                  {tabs.map(({ label, value }) => (
                    <Tab key={value} value={value}>
                      &nbsp;&nbsp;{label}&nbsp;&nbsp;
                    </Tab>
                  ))}
                </TabsHeader>
              </Tabs>
            )}
            {handleSearch && (
              <div className="w-full md:w-72">
                <Input
                  label="Search"
                  icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                  onChange={(e) => handleSearch(e.target.value)
                  }
                />
              </div>
            )}
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {heads.map((head, index) => (
                  <th
                    key={head}
                    className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                    >
                      {head}{" "}
                      {index !== heads.length - 1 && (
                        <ChevronUpDownIcon
                          strokeWidth={2}
                          className="h-4 w-4"
                        />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((tableRow, index) => {
                const isLast = index === data.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={index}>
                    <RowCells tableRow={tableRow} classes={classes} />
                    <td className={classes}>
                      {handleUpdate && (
                        <IconButton
                          onClick={() =>
                            dispatch({
                              type: actions.OPEN_FORM,
                              objectToEdit: tableRow,
                            })
                          }
                          variant="text"
                          color="blue-gray"
                        >
                          <PencilIcon className="h-4 w-4" />
                        </IconButton>
                      )}
                      {handleDelete && (
                        <IconButton
                          onClick={() =>handleDelete(tableRow.id)}
                          variant="text"
                          className="text-main"
                        >
                          <TrashIcon className="h-4 w-4" />
                        </IconButton>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page 1 of 10
          </Typography>
          <div className="flex gap-2">
            <Button variant="outlined" color="blue-gray" size="sm">
              Previous
            </Button>
            <Button variant="outlined" color="blue-gray" size="sm">
              Next
            </Button>
          </div>
        </CardFooter>
      </Card>
      <SlideOver open={state.isFormOpen} setOpen={setOpen}>
        <FormComponent
          objectToEdit={state.objectToEdit}
          onCreate={handleAddHandler}
          onUpdate={handleUpdateHandler}
        />
      </SlideOver>
    </>
  );
};
export default Datatable;
